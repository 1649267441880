export const socialsData = {
    github: 'https://github.com/Shadkoech',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/koechshadrack/',
    instagram: '',
    codepen: '',
    twitter: 'https://twitter.com/Shad_Koech',
    reddit: '',
    blogger: '',
    medium: 'https://medium.com/@shadrackipruto',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}